
body {
  font-family: "Quicksand", sans-serif;
  font-size:   16px;
  font-weight: 400;
  line-height: 1.5;
  color:       var(--contrast-high);
}

h1 {
  font-size:   1.875rem;
  font-weight: 700;
  font-style:  normal;
  line-height: 1; /* 180% */
}

h2, h3, h4 {
  margin:  0;
  padding: 0;
}

h1 {
  font-size:     1.75rem;
  margin-bottom: 1rem;
}

h2 {
  font-size:     1.5rem;
  margin-bottom: .7rem;
}

h3 {
  font-size:     1.2rem;
  font-weight:   600;
  margin-bottom: 0.5rem;
}

h4 {
  font-size:     1rem;
  font-weight:   600;
  margin-bottom: 0.25rem;
}

button {
  font-family: Quicksand, "sans-serif";
}

p {
  margin:  0 0 0.3rem;
  padding: 0;
  &:not(:first-of-type) {
    margin-top: var(--size-base);
  }
}

.no-margin {
  margin: 0;
}

.small {
  font-size: .875rem;
}

.warn {
  color: var(--core-warn)
}

a, .link {
  transition: all 400ms ease-in-out;
  color:      var(--primary-medium);

  &:hover {
    cursor:          pointer;
    text-decoration: underline;
    color:           var(--primary-medium-high);
  }
}

p.margin-top {
  margin-top: var(--size-small);
}

.inline-icon {
  font-size:               15px;
  padding:                 0 5px 0 1px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;

  &:hover {
    cursor:                  pointer;
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
  }
}
