button.default-button {
  font-family:      var(--font-family);
  font-size:        1rem;
  font-weight:      500;
  line-height:      22px;
  position:         relative;
  height:           46px;
  padding:          0.75rem 0.9375rem;
  cursor:           pointer;
  color:            var(--contrast-high);
  border:           none;
  border-radius:    var(--size-radius);
  background-color: var(--contrast-light);

  .loader {
    position:  absolute;
    top:       50%;
    left:      50%;
    display:   none;
    width:     20px;
    height:    20px;
    transform: translate3d(-50%, -50%, 0);
  }

  // When the button directive activates the processing class, the span text is hidden and the loader is shown.
  // This ensures the button remains the same size throughout the interaction
  &.processing {
    span {
      color: transparent;
    }

    .loader {
      display: block;
    }
  }

  &:hover {
    background-color: var(--contrast-medium-light);
  }

  &:active {
    padding: 0.80rem 0.9375rem 0.65rem;
  }

  &.primary {
    color:            #ffffff;
    background-color: var(--primary-medium);

    &:hover {
      background-color: var(--primary-medium-high);
    }

    &:active {
      background-color: var(--primary-high);
    }

    .loader div {
      // border-color: var(--contrast-high) transparent transparent transparent;
    }
  }

  &.accent {
    color:            var(--contrast-high);
    background-color: var(--accent-high);

    &:hover {
      background-color: var(--accent-medium-high);
    }

    &:active {
      background-color: var(--accent-high);
    }

    .loader div {
      // border-color: var(--contrast-high) transparent transparent transparent;
    }
  }

  &.warn {
    color: var(--core-warn);

    &:hover {
      color:            #ffffff;
      background-color: var(--core-warn);
    }

    &:active {
      background-color: var(--core-warn);
    }

    .loader div {
      border-color: var(--core-warn) transparent transparent transparent;
    }
  }

  &.stroked {
    color:      var(--contrast-high);
    border:     1px solid var(--contrast-high);
    background: none;

    &:hover {
    }

    &:active {
    }

    .loader div {
      border-color: var(--contrast-high) transparent transparent transparent;
    }

    &.warn {
      font-weight: 700;
      color:       var(--core-warn);
      border:      1px solid var(--core-warn);

      &:hover {
        background-color: transparent;
      }

    }
  }

  &:disabled {
    cursor:  default;
    opacity: 75%;
  }
}

button.condensed-button {
  @extend .default-button;
  font-size: 0.875rem;
  height:    34px;
  padding:   0.375rem 0.9375rem;

  &:active {
    padding: 0.4rem 0.9375rem 0.350rem
  }

  .loader {
    width:  18px;
    height: 18px;

    div {
      width:  18px;
      height: 18px;
    }
  }
}

/*
 The social button is a special case of the default button.
 They are used on the login page to allow users to login with their Google or Apple accounts.
 The difference is that the button has a background image and the text is aligned to the left.
 */
button.social-button {
  @extend .default-button;
  font-size:       .9rem;
  position:        relative;
  display:         flex;
  flex-direction:  row;
  justify-content: space-between;
  padding-left:    62px;
  transition:      none 0.3s ease-in-out;
  text-align:      left;
  border:          1px solid var(--contrast-high);

  &:active {
    padding-left: 62px;
  }

  &.google {
    background: url("/assets/images/auth/google.svg") no-repeat 16px center;
  }

  &.apple {
    background: url("/assets/images/auth/apple.svg") no-repeat 16px center;
  }

  .loader {
    position:  absolute;
    top:       50%;
    left:      50%;
    display:   none;
    width:     20px;
    height:    20px;
    transform: translate3d(-50%, -50%, 0);
  }

  &.processing {
    position: relative;

    span {
      color: inherit;
    }

    .loader {
      position:  absolute;
      top:       50%;
      right:     10px;
      left:      auto;
      display:   block;
      width:     18px;
      height:    18px;
      transform: translate3d(-50%, -50%, 0);

      div {
        width:        18px;
        height:       18px;
        margin:       2px;
        border:       2px solid var(--contrast-high);
        border-color: var(--contrast-high) transparent transparent transparent;
      }
    }
  }
}

$spinner-size:   20px;
$spinner-weight: 2px;
.loader div {
  position:      absolute;
  display:       block;
  box-sizing:    border-box;
  width:         $spinner-size;
  height:        $spinner-size;
  margin:        0;
  animation:     loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border:        $spinner-weight solid;
  border-color:  #ffffff transparent transparent transparent;
  border-radius: 50%;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
