;
@use "breakpoints";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,200..400,0..1,0..200&display=block");

@import "bootstrap/scss/bootstrap";
@import "typography";
@import "buttons";

/* variables */
:root {
  --primar:                #ffffff;
  --accent-high:           #733569;
  --accent-medium:         #a14a93;
  --accent-medium-high:    #914384;
  --contrast-high:         #2e3339;
  --contrast-light:        #f6f7f9;
  --contrast-medium:       #afb4ba;
  --contrast-medium-high:  #70777f;
  --contrast-medium-light: #d4d8de;
  --core-background:       #ffffff;
  --core-background-light: #f0f5f4;
  --core-border:           #dbe1e9;
  --core-warn:             #f35325;
  --core-warn-transparent: rgba(243, 83, 37, 0.2);
  --primary-high:          #3d8468;
  --primary-light:         #f6faf8;
  --primary-medium-light:  #e1f5ed;
  --primary-medium:        #4aa181;
  --primary-medium-high:   #439174;
  --font-family:           "Quicksand", sans-serif;
  --size-input-padding:    0.75rem;
  --size-base:             1.25rem;
  --size-small:            0.75rem;
  --size-large:            2.5rem;
  --size-radius:           5px;
  --size-page-width:       800px;
}

[data-theme="dark"] {
  --primar:                #ffffff;
  --accent-high:           #733569;
  --accent-medium:         #a14a93;
  --accent-medium-high:    #914384;
  --contrast-high:         #e5e8ef;
  --contrast-light:        #494b51;
  --contrast-medium:       #848892;
  --contrast-medium-high:  #c4c7d0;
  --contrast-medium-light: #656871;
  --core-background:       #292a2d;
  --core-background-light: #343539;
  --core-border:           #464b52;
  --core-warn:             #f37445;
  --core-warn-transparent: rgba(243, 83, 37, 0.2);
  --primary-high:          #3d8468;
  --primary-light:         #3f4442;
  --primary-medium-light:  #404844;
  --primary-medium:        #4aa181;
  --primary-medium-high:   #439174;
  --font-family:           "Quicksand", sans-serif;
  --size-input-padding:    0.75rem;
  --size-base:             1.25rem;
  --size-small:            0.75rem;
  --size-large:            2.5rem;
  --size-radius:           5px;
  --size-page-width:       800px;
}

body, html {
  margin:           0;
  padding:          0;
  background-color: var(--core-background);
}

hr {
  margin:     var(--size-base) 0 var(--size-base);
  border:     none;
  border-top: 1px solid var(--core-border)
}

/* Page structure
A module contains a page-nav, and a router in which the children are displayed
Children are contained in a page-container, with an optional secondary-nav
The actual page content contained by the page-content container
*/

.module-container {
  display:        flex;
  align-items:    center;
  flex-direction: column;
  width:          100%;
  margin-top:     40px;
  padding-bottom: 5rem;

  .page-container {
    display:               grid;
    max-width:             var(--size-page-width);
    grid-template-columns: auto;

    &.with-aside {
      width:                 100%;
      max-width:             var(--size-page-width);
      gap:                   1rem;
      grid-template-columns: auto 50px;
    }

    .page-content {
      flex:        1;
      order:       1;
      padding-top: var(--size-large);
      border-top:  1px solid var(--core-border);

      section.page-header {
        margin-bottom: var(--size-large);
      }
    }

    nav.secondary-nav {
      flex-shrink: 1;
      order:       2;
      padding-top: var(--size-large);
      border-top:  1px solid var(--core-border);

      .nav-item {
        display:       block;
        padding:       10px;
        border-radius: var(--size-radius);

        &:hover {
          font-weight:      500;
          cursor:           pointer;
          background-color: var(--contrast-light);
        }

        &.active {
          font-weight: 700;
        }
      }
    }
  }
}

.two-columns {
  display: flex;
  gap:     var(--size-large);

  @include breakpoints.layout-bp(lt-md) {
    flex-direction: column;
  }

  > * {
    flex: 1;
  }
}

section.settings-section {
  position:      relative;
  margin-bottom: 4rem;
  padding:       var(--size-large);
  border:        1px solid var(--core-border);
  border-radius: var(--size-radius);
  // box-shadow:    rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .section-header {
    margin-bottom: var(--size-base);

    > h3, > h2, > .section-label {
      font-size:        1rem;
      display:          inline-block;
      margin-top:       0;
      margin-bottom:    0.5rem;
      background-color: var(--core-background);
    }

    > p {
      // font-size: 15px;
    }
  }

}

.actions > * {
  display:       block;
  border-bottom: 1px solid var(--core-border);

  &:last-child {
    padding-bottom: 0;
    border-bottom:  none;
  }
}

/** Styles for the article cards need to be set here, because they are set through innerHTML **/
.card-content {
  p {
    margin: 0 0 0.5rem;
  }

  li {
    margin: 0.5rem;
  }

  h1, h2, h3 {
    margin-top:    20px;
    margin-bottom: 5px;
  }

  table {
    width:           100%;
    margin:          0 0 2rem;
    padding:         0;
    border-spacing:  0;
    border-collapse: collapse;
    border:          1px solid var(--core-border);
    background:      none;

    th, td {
      padding: var(--size-small);
    }
  }
}

/*
 Loader animation from https://loading.io/css/
 The loader is centered in the button using absolute positioning
 */
$spinner-size:   20px;
$spinner-weight: 2px;
.loader {
  display:         flex;
  align-items:     center;
  justify-content: center;
  width:           100%;
  height:          $spinner-size;
}

.loader div {
  position:      absolute;
  display:       block;
  box-sizing:    border-box;
  width:         $spinner-size;
  height:        $spinner-size;
  margin:        0;
  animation:     loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border:        $spinner-weight solid;
  border-color:  #ffffff transparent transparent transparent;
  border-radius: 50%;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

